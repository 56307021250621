import { PrismicNextLink } from '@prismicio/next';
import { PrismicRichText as __PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { linkResolver } from 'prismic/src/prismicio';
import { ComponentPropsWithoutRef, FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './PrismicRichText.module.scss';

const RemoteGuideIFrame = dynamic(() => import('../RemoteGuideIFrame/index'));

interface PrismicRichTextProps extends ComponentPropsWithoutRef<typeof __PrismicRichText> {
  applyStyles?: boolean;
  showImageAsPopup?: boolean;
  imageAlignment?: 'block' | 'left' | 'right';
  boldHighlight?: 'primary' | 'green';
  className?: string;
  imagePopupVariant?: 'dark_overlay';
}

const components = {
  hyperlink: ({ children, ...props }) => {
    const data = props?.node?.data || {};
    if (data.link_type === 'Web' && !data.url?.startsWith('/')) {
      return (
        <a
          href={data.url}
          target={data.target}
          rel={data.target === '_blank' ? 'nofollow noreferrer' : 'noopener noreferrer'}
        >
          {children}
        </a>
      );
    }
    return (
      <PrismicNextLink field={data} linkResolver={linkResolver}>
        {children}
      </PrismicNextLink>
    );
  },
};

export const PrismicRichText: FC<PrismicRichTextProps> = props => {
  const { applyStyles, showImageAsPopup, imageAlignment, boldHighlight, className, imagePopupVariant, ...rest } = props;

  const [showIframe, setShowIframe] = useState<boolean>(false);
  const [iframeLink, setIframeLink] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && showImageAsPopup) {
      ref.current.addEventListener('click', e => {
        if ((e.target as any).nodeName === 'IMG') {
          setShowIframe(true);
          if (imagePopupVariant === 'dark_overlay') {
            document.body.style.overflowY = 'hidden';
          }
          setIframeLink((e.target as any).src);
        }
      });
    }
  }, [imagePopupVariant, showImageAsPopup]);

  if (applyStyles) {
    return (
      <>
        <div
          className={classNames(
            styles.root,
            {
              [styles.root__imagePopup]: showImageAsPopup,
              [styles[`root__image-${imageAlignment}`]]: showImageAsPopup,
              [styles[`root__bold-${boldHighlight}`]]: boldHighlight,
            },
            className,
          )}
          ref={ref}
        >
          <__PrismicRichText components={components} {...rest} />
        </div>
        {showIframe && iframeLink && (
          <>
            {imagePopupVariant ? (
              <ImageOverlay
                handleClose={() => {
                  setShowIframe(false);
                }}
                imageUrl={iframeLink}
              />
            ) : (
              <RemoteGuideIFrame link={iframeLink} setShowIframe={setShowIframe} type="image" />
            )}
          </>
        )}
      </>
    );
  }

  return <__PrismicRichText components={components} {...rest} />;
};

const ImageOverlay = ({ imageUrl, handleClose }) => {
  useEffect(() => {
    const handleClickOutside = () => {
      handleClose();
      document.body.style.overflowY = 'scroll';
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClose]);

  return createPortal(
    <div className={styles.overlay}>
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt="Popup image" title="Loading Image" />
      </div>
    </div>,
    document.getElementById('imagePopupPortalElement'),
  );
};

export default ImageOverlay;

PrismicRichText.defaultProps = {
  applyStyles: true,
};
