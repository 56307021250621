import styles from './ResourcesTable.module.scss';

const ResourcesTable = ({ blogArray }) => {
  return (
    <div className={styles.root}>
      <h2>Table of Contents</h2>
      <ul className={styles.root}>
        {blogArray.map(({ section_heading, href, id, headingNumber }) => {
          const itemClass = `item-${headingNumber}`;
          const className = styles[itemClass];
          return (
            <li key={section_heading}>
              <a href={href} className={className}>
                {id} {section_heading}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ResourcesTable;
