import Image from 'next/image';
import { isValidElement } from 'react';
import styles from './ResourcesAuthor.module.scss';

const ResourcesAuthor = ({ authorBio }) => {
  const title = authorBio?.length > 1 ? 'Authors' : 'Author';
  return (
    <div className={styles.root}>
      <h2>{title}</h2>
      <ul>
        {authorBio?.map(({ headshot, author_name, author_bio }) => (
          <li key={author_name} className={styles.flex}>
            <div className={styles.imgWrapper}>
              {(isValidElement(headshot) || headshot?.url) &&
                (isValidElement(headshot) ? (
                  headshot
                ) : (
                  <Image src={headshot.url} alt={headshot.alternativeText || 'Author'} width={120} height={120} />
                ))}
            </div>
            <div className={styles.info}>
              <h3>{author_name}</h3>
              <p className={styles.bio}>{author_bio}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResourcesAuthor;
