import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useScrollY } from 'ui/hooks/useScrollY';

interface VisibleProps {
  isVisible: (inView: boolean) => void;
  className?: string;
  min?: number;
  max?: number;
  disableMax?: boolean;
}

const Visible: FC<PropsWithChildren<VisibleProps>> = props => {
  const { children, isVisible, className, min, max, disableMax } = props;
  const childRef = useRef(null);
  const scrollY = useScrollY();

  useEffect(() => {
    const { y } = childRef.current.getBoundingClientRect();
    isVisible?.(disableMax ? y > (min ? min : 25) : y > (min ? min : 25) && y < (max ? max : 200));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  return (
    <>
      {React.cloneElement(children as any, {
        ref: ref => (childRef.current = ref),
      })}
    </>
  );
};

export default Visible;
