import { Carousel } from 'ui/components/Carousel/Carousel';
import PostPreview from 'ui/components/PostPreview';
import { Section } from 'ui/sections/section';
import { isArrayNotEmpty } from 'ui/utils/helpers/array.helper';
import { SolidBlueLeft, SolidBlueRight } from 'ui/components/Carousel/Controls/SolidBlue';

import styles from './BlogCardsSection.module.scss';

export const BlogCards = ({ list = undefined, isNoscript = undefined }) => {
  return (
    <div className={styles.root}>
      {isArrayNotEmpty(list) && (
        <Carousel
          withControls
          withIndicators
          slideSize={260}
          slideGap="md"
          breakpoints={[
            {
              maxWidth: 'sm',
              slideGap: 'lg',
              withControls: false,
            },
          ]}
          classNames={{
            nextControlIcon: styles.nextControlIcon,
            previousControlIcon: styles.previousControlIcon,
          }}
          previousControlIcon={<SolidBlueLeft />}
          nextControlIcon={<SolidBlueRight />}
        >
          {list.map((post, i) => {
            return (
              <Carousel.Slide key={i}>
                <PostPreview
                  {...post}
                  className={styles.postpreview}
                  isNoscript={isNoscript}
                />
              </Carousel.Slide>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default function BlogCardsSection({
  list = undefined,
  title = 'Latest posts from Turing',
  subTitle = undefined,
  isSubtitleHTML = undefined,
  sectionProps = undefined,
  isNoscript = false,
  paddingSectionTitle=true,
}) {
  return (
    <Section
      title={title}
      subTitle={subTitle}
      bg="gray"
      titleClassName={paddingSectionTitle ? styles.headline : ''}
      isSubtitleHTML={isSubtitleHTML}
      {...sectionProps}
    >
      <BlogCards list={list} isNoscript={isNoscript} />
    </Section>
  );
}
