import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { isValidElement, useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'ui/components/Button';
import { ResponsiveImage } from 'ui/components/ResponsiveImage';
import { RichText } from 'ui/components/RichText';
import { VideoModalButton } from 'ui/components/VideoModalButton';
import { Section } from 'ui/sections/section';
import { constants } from 'ui/utils/constants';
import { useRouter } from 'next/router';
import { LogFormFills } from 'ui/utils/analytics/InternalLogger';
import { AppContext } from 'ui/utils/app-context';
import { isArrayNotEmpty } from 'ui/utils/helpers/array.helper';
import ResourceBlogsSection from '../ResourceBlogsSection/ResourceBlogsSection';
import ResourcesAuthor from '../ResourcesAuthor/ResourcesAuthor';
import ResourcesTable from '../ResourcesTable/ResourcesTable';
import styles from './ResourcesContent.module.scss';
import { HubspotCSS } from './hubspot-form';

const DynamicReactModal = dynamic(import('react-modal'));

const LazyLoadComponent = dynamic(() => import('ui/components/LazyLoadComponent'), { ssr: false });

const DynamicDeveloperProfileCardList = dynamic(() =>
  import('ui/components/DeveloperProfileCard/DeveloperProfileCardList').then(mod => mod.DeveloperProfileCardList),
);

const DynamicRemoteJobsSingleCardSlider = dynamic(() =>
  import('ui/components/RemoteJobsSingleCardSlider/RemoteJobsSingleCardSlider').then(
    mod => mod.RemoteJobsSingleCardSlider,
  ),
);

const ResourcesContent = ({
  content = undefined,
  otherPosts,
  developers,
  jobOpenings = undefined,
  coverImg,
  coverVideoId,
  blogString,
  blogArray,
  authorBio,
  blog_intro = undefined,
  type = 'supply',
  isIMCContent,
  hubspotForms = [],
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState({});
  const stickyWrapper = useRef(null);
  const ctx = useContext(AppContext);
  const { asPath } = useRouter();

  useEffect(() => {
    const observer = new ResizeObserver(event => {
      const height = event[0]?.contentRect.height;
      if (height) {
        if (height < window.innerHeight - 100) {
          stickyWrapper.current.style.top = `100px`;
        } else {
          stickyWrapper.current.style.top = `calc(100vh - ${height}px - 30px)`;
        }
      }
    });
    observer.observe(stickyWrapper.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const initializeCardForm = () => {
    hubspotForms.forEach(form => {
      if (form.type === 'Card') {
        hbspt.forms.create({
          region: 'na1',
          portalId: '8835306',
          formId: form.id,
          target: `#hubspotForm_${form.id}`,
          onFormReady: function ($form) {
            const emailField = $form.querySelector(`input[type="email"]`);
            if (emailField) {
              emailField.addEventListener('blur', () => {
                LogFormFills(asPath, ctx.visitor?.visitorId, {
                  email: emailField.value,
                  target: `#hubspotForm_${form.id}`,
                  formId: form.id,
                  portalId: '8835306',
                  slice: 'ResourceContent',
                });
              });
            }
          },
        });
      }
    });
  };

  const initializePopupForm = formId => {
    hbspt.forms.create({
      region: 'na1',
      portalId: '8835306',
      formId: formId,
      target: `#hubspotForm_${formId}`,
      onFormReady: function ($form) {
        const formElements = $form.querySelectorAll(`input`);
        formElements.forEach(element => {
          element.addEventListener('blur', () => {
            // Retrieve the entire form data
            const formData = new FormData($form);

            // Convert FormData to a plain object
            const formValues = Object.fromEntries(formData.entries());
            LogFormFills(asPath, ctx.visitor?.visitorId, {
              ...formValues,
              target: `#hubspotForm_${formId}`,
              formId: formId,
              portalId: '8835306',
              slice: 'ResourceContent',
            });
          });
        });
      },
    });
  };

  const getRelatedPosts = () => {
    if (isIMCContent) {
      return otherPosts.map(post => ({
        ...post,
        image: { url: null },
      }));
    }
    return otherPosts;
  };

  return (
    <Section topPad="sm">
      <div
        className={classNames(styles.root, {
          [styles.root__imcTemplate]: isIMCContent,
        })}
      >
        <div className={styles.content}>
          {(isValidElement(coverImg) || !!coverImg?.src) && (
            <div className={styles.imgWrapper}>
              {isValidElement(coverImg) ? (
                coverImg
              ) : (
                <ResponsiveImage
                  src={coverImg.src}
                  alt={coverImg.alt}
                  width={coverImg.width}
                  height={coverImg.height}
                  blurHash={coverImg.blurDataURL}
                  layout="responsive"
                  priority
                />
              )}
              {coverVideoId && (
                <VideoModalButton
                  watchVideoBtn={{
                    title: '',
                    videoId: coverVideoId,
                  }}
                  className={styles.heroVideoButton}
                />
              )}
            </div>
          )}
          {blogString ? (
            <>
              {blog_intro &&
                (isValidElement(blog_intro) ? (
                  blog_intro
                ) : (
                  <RichText className={styles.richText} htmlText={blog_intro} />
                ))}
              {isArrayNotEmpty(blogArray) && <ResourcesTable blogArray={blogArray} />}
              <RichText className={styles.richText} htmlText={blogString} />
            </>
          ) : isValidElement(content) ? (
            content
          ) : (
            <RichText className={styles.richText} htmlText={content} />
          )}

          {isArrayNotEmpty(authorBio) && <ResourcesAuthor authorBio={authorBio} />}
        </div>

        <aside className={styles.aside}>
          <div className={styles.aside__wrapper} ref={stickyWrapper}>
            {isArrayNotEmpty(otherPosts) && (
              <div className={styles.latestPosts}>
                <h3>{rest.latest_posts_heading}</h3>
                <ResourceBlogsSection list={getRelatedPosts()} isScroll={!isIMCContent} />
              </div>
            )}
            {isArrayNotEmpty(developers) && type === 'demand' && (
              <LazyLoadComponent className={styles.devCards}>
                <div className={styles.devCardsContent}>
                  <h3>{rest.hire_developers_heading}</h3>
                  <DynamicDeveloperProfileCardList
                    profiles={developers}
                    cta="default"
                    cardClassName={styles.profileCard}
                    carouselProps={{
                      dragFree: false,
                      slideSize: 388,
                      slideGap: 'xl',
                      align: 'center',
                      autoPlay: true,
                    }}
                  />
                </div>
              </LazyLoadComponent>
            )}

            {isArrayNotEmpty(jobOpenings) && type === 'supply' && (
              <LazyLoadComponent className={styles.jobCards}>
                <h3>{rest.jobs_heading}</h3>
                <DynamicRemoteJobsSingleCardSlider
                  url={constants.DEVELOPER_FUNNEL_URL}
                  list={jobOpenings}
                  showCTACard={false}
                />
              </LazyLoadComponent>
            )}

            {isArrayNotEmpty(hubspotForms) && (
              <>
                <style dangerouslySetInnerHTML={{ __html: HubspotCSS }}></style>
                {hubspotForms.map(form => {
                  return (
                    <div className={styles.hbForm} key={form.id}>
                      <h3 className={styles.hbForm__title}>{form.heading}</h3>
                      {form.type === 'Card' && <div id={`hubspotForm_${form.id}`}></div>}
                      {form.type === 'Popup' && (
                        <Button onClick={() => setIsOpen({ [form.id]: true })}>{form.cta}</Button>
                      )}
                      {form.type === 'Popup' && (
                        <DynamicReactModal
                          isOpen={!!isOpen[form.id]}
                          style={{
                            content: {
                              top: '50%',
                              left: '50%',
                              right: 'auto',
                              bottom: 'auto',
                              marginRight: '-50%',
                              transform: 'translate(-50%, -50%)',
                              width: 700,
                            },
                          }}
                          onAfterOpen={() => initializePopupForm(form.id)}
                        >
                          <span role="presentation" onClick={() => setIsOpen({})} className={styles.tte__modalClose}>
                            close
                          </span>
                          <div id={`hubspotForm_${form.id}`} className={styles.tte__form}></div>
                        </DynamicReactModal>
                      )}
                    </div>
                  );
                })}
                <Script
                  strategy="lazyOnload"
                  onLoad={initializeCardForm}
                  charset="utf-8"
                  type="text/javascript"
                  src="//js.hsforms.net/forms/embed/v2.js"
                />
              </>
            )}
          </div>
        </aside>
      </div>
    </Section>
  );
};
export default ResourcesContent;
