import { asHTML } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { linkResolver } from 'prismic/src/prismicio';
import { constants } from 'ui/utils/constants';
import { getDomain } from 'ui/utils/helpers/env-helper';

const getRelAttribute = (url: string) => {
  const urlObject = new URL(url);
  const internalParam = urlObject.searchParams.get('internal_rel');

  if (internalParam) {
    const params = internalParam.split(' ');
    const hasFollow = params.includes('follow');
    const hasReferrer = params.includes('referrer');

    if ((hasFollow && hasReferrer) || urlObject.hostname.includes('turing.com') ||  urlObject.hostname.includes('localhost')) {
      return ''; // Do not add any rel attributes
    }

    if (hasFollow) {
      return 'rel="noreferrer"';
    }

    if (hasReferrer) {
      return 'rel="nofollow"';
    }
  }
  if (urlObject.hostname.includes('turing.com') ||  urlObject.hostname.includes('localhost')) {
    return ''; // Do not add any rel attributes
  }

  return 'rel="nofollow noreferrer"';
};
function removeQueryParam(url, param) {
  try {
    const urlObj = new URL(url);
    urlObj.searchParams.delete(param);
    return urlObj.toString();
  } catch (error) {}
  return url;
}

const serializer: HTMLMapSerializer = {
  hyperlink: payload => {
    const data: any = payload?.node?.data || {};
    const relAttribute = data.target === '_blank' && data?.url?.startsWith('http') ? getRelAttribute(data?.url) : '';
    const finalUrl = removeQueryParam(data?.url, 'internal_rel');
    return `<a href="${finalUrl}" target="${data.target}" ${relAttribute}>${payload.children}</a>`;
  },
};

const convertToId = str => {
  return str?.toLowerCase().replace(/\s$/, '').replace(/\s/g, '-') || '';
};

const getHeadingNumbers = (blog = []) => {
  return blog.map(({ heading_type }) => parseInt(heading_type?.slice(1) || 2));
};

const isHeadingNumsValid = (headingNums = []) => headingNums[0] === 2;

const getEmptyBlogIds = (headingNums = []) => {
  return Array.from({ length: headingNums.length }, () => '');
};

const initHeadingValues = (length = 5) => {
  const initialValues = Array.from({ length }, (_, i) => i + 1).reduce((acc, curr) => {
    acc.set(curr, 0);
    return acc;
  }, new Map());
  return initialValues;
};

const getDigits = (v, num) => {
  switch (num) {
    case 2:
      return `${v.get(2)}.`;
    case 3:
      return `${v.get(2)}.${v.get(3)}.`;
    case 4:
      return `${v.get(2)}.${v.get(3)}.${v.get(4)}.`;
    case 5:
      return `${v.get(2)}.${v.get(3)}.${v.get(4)}.${v.get(5)}.`;
    case 6:
      return `${v.get(2)}.${v.get(3)}.${v.get(4)}.${v.get(5)}.${v.get(6)}.`;
    default:
      return '';
  }
};

const getBlogIds = (headingNums = []) => {
  if (!isHeadingNumsValid(headingNums)) {
    return getEmptyBlogIds(headingNums);
  }
  const currValues = initHeadingValues();
  const ids = [];
  headingNums.forEach(num => {
    currValues.set(num, currValues.get(num) + 1);
    currValues.forEach((_, key) => {
      if (key > num) {
        currValues.set(key, 0);
      }
    });
    const id = getDigits(currValues, num);
    ids.push(id);
  });
  return ids;
};

export const parsePrismicResourcesBlog = blog => {
  const safeBlog = Array.isArray(blog) ? blog : [];
  const blogMarkdownArray = safeBlog.map(blogItem => {
    const level = blogItem?.heading_type?.toLowerCase() || 'h2';
    const title = blogItem?.title || '';
    const description = asHTML(blogItem?.content, { linkResolver, serializer }).toString() || '';
    const sectionHeading = blogItem?.title || '';
    const markupId = convertToId(sectionHeading);
    const safeId = markupId ? `id="${markupId}"` : '';
    const descriptionWithHeading = title ? `<${level} ${safeId}>${title}</${level}>\n\n${description}` : '';
    return descriptionWithHeading;
  });
  const blogString = blogMarkdownArray.join('\n');

  const headingNumbers = getHeadingNumbers(safeBlog);
  const blogIds = getBlogIds(headingNumbers);
  const filteredSafeBlog = safeBlog.filter(({ title }) => title);
  const blogArray = filteredSafeBlog.map(({ title, ...rest }, index) => {
    const href = `#${convertToId(title)}`;
    const id = blogIds[index];
    const headingNumber = headingNumbers[index];
    return { href, id, section_heading: title, headingNumber, ...rest };
  });

  return { blogString, blogArray };
};

export const parseResourcesBlog = blog => {
  const safeBlog = Array.isArray(blog) ? blog : [];
  const blogMarkdownArray = safeBlog.map(blogItem => {
    const level = blogItem?.heading_type?.toLowerCase() || 'h2';
    const title = blogItem?.section_heading || '';
    const description = blogItem?.description || '';
    const sectionHeading = blogItem?.section_heading || '';
    const markupId = convertToId(sectionHeading);
    const safeId = markupId ? `id="${markupId}"` : '';
    const descriptionWithHeading = title ? `<${level} ${safeId}>${title}</${level}>\n\n${description}` : '';
    return descriptionWithHeading;
  });
  const blogString = blogMarkdownArray.join('\n');

  const headingNumbers = getHeadingNumbers(safeBlog);
  const blogIds = getBlogIds(headingNumbers);
  const filteredSafeBlog = safeBlog.filter(({ section_heading }) => section_heading);
  const blogArray = filteredSafeBlog.map(({ section_heading, ...rest }, index) => {
    const href = `#${convertToId(section_heading)}`;
    const id = blogIds[index];
    const headingNumber = headingNumbers[index];
    return { href, id, section_heading, headingNumber, ...rest };
  });

  return { blogString, blogArray };
};

export const getResourcesMasterPaths = () => {
  const paths = ['resources'];
  const names = ['Hiring Resources'];
  return { paths, names };
};

export const getKbMasterPaths = () => {
  const paths = ['kb'];
  const names = ['Knowledge Base'];
  return { paths, names };
};

export const getResourcesSpecificPaths = (pagepath = '', pagename = '') => {
  const { paths: prevPaths, names: prevNames } = getResourcesMasterPaths();
  const paths = [...prevPaths, `resources/${pagepath}`];
  const names = [...prevNames, pagename];
  return { paths, names };
};

export const getKbSpecificPaths = (pagepath = '', pagename = '') => {
  const { paths: prevPaths, names: prevNames } = getKbMasterPaths();
  const paths = [...prevPaths, `kb/${pagepath}`];
  const names = [...prevNames, pagename];
  return { paths, names };
};

export const getArticleSnippet = (cover_title, cover_image, authorBio = [], publshedAt) => {
  const authors = authorBio
    .filter(({ author_name }) => author_name)
    .map(({ author_name }) => {
      return { '@type': 'Person', name: author_name };
    });
  // For a single author, build an object.
  // And for multiple authors, use an array of objects.
  const authorEntries = authors.length === 1 ? authors[0] : authors;
  const isAuthorEntriesValid = Array.isArray(authorEntries)
    ? authorEntries.length !== 0
    : typeof authorEntries === 'object' && authorEntries !== null;
  const img = cover_image?.src;
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    ...(cover_title && { headline: cover_title }),
    ...(img && { image: img }),
    ...(isAuthorEntriesValid && { author: authorEntries }),
    publisher: {
      '@type': 'Organization',
      name: 'Turing Enterprises Inc',
      logo: {
        '@type': 'ImageObject',
        url: `${getDomain()}/img/new-logo.svg`,
      },
    },
    ...(publshedAt && { datePublished: publshedAt }),
  };
};

export const getResourcesFaq = (FAQ = [], isDeveloperOnlyPage = false, preTitle = '') => {
  const isEmployer = !isDeveloperOnlyPage && preTitle?.toLowerCase()?.includes('employers');
  const viewMoreHref = isEmployer ? constants.CUSTOMER_FAQ_URL : constants.DEVELOPER_FAQ_URL;
  return { FAQ: FAQ, viewMoreHref };
};

export const filterAuthorBio = (authorBio = []) =>
  authorBio.filter(({ headshot, author_name, author_bio }) => headshot?.url && author_name && author_bio);
