export const HubspotCSS = `
fieldset.form-columns-2 {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
  }
  
  .hs-form-field {
    margin-bottom: 22px;
  }
  
  .form-columns-2 > .hs-form-field {
    width: unset !important;
    float: unset !important;
  }
  
  .hs-form-field label {
    font-weight: 500;
    color: #33475b;
    font-family: Poppins;
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
  }
  
  .hs-form-field input {
    background: #33475b0a;
  }
  
  .hs-richtext {
    font-size: 13px;
  }
  
  .form-columns-1 {
    max-width: 100% !important;
    color: #666;
  }
  
  .hs-fieldtype-booleancheckbox {
    margin-bottom: 22px !important;
    display: block;
  }
  
  .hs-button {
    border-radius: 4px;
  }
  .hs-error-msg {
    font-weight: normal !important;
    color: #e45b5b !important;
  }
  .hs_error_rollup {
    margin-bottom: 22px;
    color: #e45b5b;
  }
  .submitted-message {
    margin-bottom: 32px;
    text-align: center;
  }  
`;
