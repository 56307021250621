import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';
import rehypeStringify from 'rehype-stringify';
import { fallbackText } from 'ui/utils/fallback-text';
import axios from 'axios';

/**
 * Converts markdown content to HTML string. This function should only be called
 *   at the build-time (getStaticProps).
 * @param {string | null | undefined} markdownData Data mostly coming from
 *   the CMS. It can potentially be null or undefined.
 * @returns {Promise<string>} HTML string
 */
export const convertMarkdownToHTML = async markdownData => {
  const markdownString = fallbackText(markdownData);
  const content = await unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(rehypeRaw)
    .use(rehypeStringify)
    .process(markdownString);
    // return content.toString();
  // Note: To enable the plugin comment out the lines below
  const contMod = convertToSvg(content.toString());
  return contMod;
};

const fetchSVG = async (url = '') => {
  return new Promise(async (resolve, reject) => {
    const finalUrl = new URL(url);
    const fileExtension = (finalUrl.pathname || '').split('.').pop();
    if (fileExtension === 'svg') {
      const { data } = await axios.get(finalUrl.href);
      resolve(data);
    }
    resolve(url);
  });
};

/**
 * 
 * @param {string} html 
 * @returns {string}
 * @description This will find out the .svg links in image tag and convert them to actual svg tag in given html string
 */
export const convertToSvg = async html => {
  let finalHtml = html;
  // regex to fetch all image tags
  const reg1 = /<img .+?\/?\>/g;

  // regex to fetch url with svg
  const reg2 = /(https?:\/\/.*\.(?:svg).*?\")/g;

  // fetch url with src attribute
  const reg3 = /src="(.*?)"/g;

  // Make sure to get only image tags
  const imageTags = html.match(reg1);
  if (imageTags) {
    for (let i = 0; i < imageTags.length; i++) {
      const tag = imageTags[i];
      

      // Assuming link will have https url in src attribute
      const imageUrlWithSrc = tag.match(reg3);

      // console.log(tag, imageUrlWithSrc)
      if (imageUrlWithSrc && imageUrlWithSrc.length > 0) {
        const imageUrl = imageUrlWithSrc[0].match(reg2);
        if (imageUrl && imageUrl.length > 0) {
          const url = new URL(imageUrl[0].replace('"', ''));
          if (url.searchParams.get('img2svg') != 'false' && url.searchParams.get('enableEnlargeInModal') !== 'true') {
            let replace = true;
            const svgBody = await fetchSVG(url.href).catch(error => {
              replace = false;
            });
            if (replace) {
              finalHtml = finalHtml.replace(tag, svgBody);
            }
          }
        } else {
          // add lazy load attribute here
          const tagWithLazyAttribute = tag.replace("<img", '<img loading="lazy"');
          finalHtml = finalHtml.replace(tag, tagWithLazyAttribute);
        }
        
      }
    }
  }
  return finalHtml;
};
