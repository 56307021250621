export const getIntroWordsCount = (introList: any[] = []): number => {
  if (introList.length === 0) {
    return 0;
  }

  return introList.reduce((totalWords, intro) => {
    return totalWords + (intro.text?.split(/\s+/)?.length || 0);
  }, 0);
};

export const getContentWordsCount = (content: string = ''): number => {
  if (content.trim() === '') {
    return 0;
  }

  const strippedText = content.replace(/<[^>]*>/g, '');
  const words = strippedText.split(/\s+/);
  const filteredWords = words.filter(word => word.trim() !== '');
  return filteredWords.length;
};

// Function to get content words count for each item
export const getTotalContentWordsCount = (items: any[]): number => {
  return items.reduce((total, item) => {
    const contentList = item.content.map((contentItem: any) => ({ text: contentItem.text }));
    return total + getIntroWordsCount(contentList);
  }, 0);
};