import React from 'react';
import PostPreview from 'ui/components/PostPreview';
import Scrollbars from 'ui/components/Scrollbars/Scrollbars';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { BlogCards } from 'ui/sections/BlogCardsSection';
import styles from './ResourceBlogsSection.module.scss';

const ResourceBlogsSection = ({ list = [], isScroll = true }) => {
  const [windowWidth] = useWindowsSize();

  const isMobile = windowWidth < 1024;

  const renderContent = () => {
    return (
      <div className={styles.root}>
        {list.map((ele, index) => {
          return <PostPreview key={index} {...ele} layout="row" className={styles.post} />;
        })}
      </div>
    );
  };

  if (isMobile) {
    return <BlogCards list={list} />;
  }

  if (isScroll) {
    return <Scrollbars>{renderContent()}</Scrollbars>;
  }

  return renderContent();
};
export default ResourceBlogsSection;
