import { Content, asLink, asText, asImageSrc } from '@prismicio/client';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import { PrismicLink, SliceComponentProps } from '@prismicio/react';
import React, { useContext, useEffect, useState } from 'react';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import ResourcesContent from 'ui/components/Resources/ResourcesContent/ResourcesContent';
import { parsePrismicResourcesBlog } from 'ui/utils/helpers/resources.helper';
import { convertMarkdownToHTML } from 'ui/utils/text/convertMarkdownToHTML';
import Head from 'next/head';
import classNames from 'classnames';
import { SVGLibrary } from 'ui/svgs/library';
import Visible from 'ui/components/Visibile/Visible';
import Link from 'next/link';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import Script from 'next/script';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { useRouter } from 'next/router';
import { LogFormFills } from 'ui/utils/analytics/InternalLogger';
import { AppContext } from 'ui/utils/app-context';
import { getContentWordsCount, getIntroWordsCount } from './blogContent.helper';
import styles from './BlogContent.module.scss';
import { HubspotCSS } from './hubspot-form';

export const MAX_FADE_OUT_TIME = 3000; // in ms
/**
 * Props for `BlogContent`.
 */
export type BlogContentProps = SliceComponentProps<Content.BlogContentSlice>;

/**
 * Component for "BlogContent" Slices.
 */
const BlogContent = ({ slice, context }: BlogContentProps): JSX.Element => {
  const [blogString, setBlogString] = useState<string>('');
  const [blogArray, setBlogArray] = useState<any>([]);
  const { items, primary, slice_type, variation } = slice;
  const [activeLink, setActiveLink] = useState(items?.[0] || null);
  const [width] = useWindowsSize();
  const ctx = useContext(AppContext);
  const { asPath } = useRouter();

  useEffect(() => {
    const calculateBlogValues = async () => {
      const { blogString, blogArray } = parsePrismicResourcesBlog(items);
      const blogStringHTML = await convertMarkdownToHTML(blogString);
      setBlogString(blogStringHTML as string);
      setBlogArray(blogArray);
    };
    calculateBlogValues();
  }, [items]);

  if (variation === 'default') {
    const {
      intro,
      blog_content,
      image,
      video_id,
      author_bio,
      author_image,
      author_name,
      is_imc_content,
      developers,
      related_posts,
      read_more_text,
      hire_developers_heading,
      latest_posts_heading,
      jobs_heading,
      content_type,
      form_1_heading,
      form_1_cta,
      form_1_type,
      form_1_hubspot_id,
      form_2_heading,
      form_2_cta,
      form_2_type,
      form_2_hubspot_id,
    } = primary;

    const otherPosts = (related_posts as any)?.data?.documents
      ?.map(item => {
        const data = item?.document?.data;
        if (!data) {
          return null;
        }
        return {
          blog_url: asLink(data.url),
          title: data.title,
          short_snippet: data.content,
          image: <PrismicNextImage field={data.image} height={62} width={100} />,
          read_more_text: data.cta || read_more_text,
        };
      })
      .filter(item => !!item);

    let developerProfileCards;
    let jobOpenings;
    if (content_type === 'supply') {
      jobOpenings = (developers as any)?.data?.documents
        ?.map(item => {
          const data = item?.document?.data;
          return data;
        })
        .filter(item => !!item);
    } else {
      developerProfileCards = (developers as any)?.data?.documents
        ?.map(item => {
          const data = item?.document?.data;
          if (!data) {
            return null;
          }
          return {
            developer_profile_card: {
              id: data.dev_id,
              dev_name: data.name,
              dev_role: data.role,
              dev_description: data.description,
              main_skills: data.main_skills,
              other_skills: data.other_skills,
              experience: data.experience,
              availability: data.availability,
              dev_image: <PrismicNextImage field={data.image} height={48} width={48} />,
              dev_id: data.dev_id,
            },
          };
        })
        .filter(item => !!item);
    }

    const authorBio = {
      headshot: <PrismicNextImage field={author_image} width={120} height={120} />,
      author_name: author_name,
      author_bio: author_bio,
    };

    const getHubspotForms = () => {
      const forms = [];

      if (form_1_heading && form_1_hubspot_id && form_1_type && form_1_cta) {
        forms.push({
          heading: form_1_heading,
          cta: form_1_cta,
          type: form_1_type,
          id: form_1_hubspot_id,
        });
      }
      if (form_2_heading && form_2_hubspot_id && form_2_type && form_2_cta) {
        forms.push({
          heading: form_2_heading,
          cta: form_2_cta,
          type: form_2_type,
          id: form_2_hubspot_id,
        });
      }

      return forms;
    };

    const introWords = getIntroWordsCount(intro);
    const contentWords = getContentWordsCount(blogString);
    const readingtimes = Math.ceil((introWords + contentWords) / 225);

    return (
      <>
        <Head>
          <meta name="read_length" content={String(readingtimes || 0)} className="elastic" />
        </Head>
        <ResourcesContent
          data-slice-type={slice_type}
          data-slice-variation={variation}
          isIMCContent={is_imc_content}
          blog_intro={<PrismicRichText field={intro} />}
          content={<PrismicRichText field={blog_content} />}
          blogString={blogString}
          blogArray={blogArray}
          otherPosts={otherPosts}
          developers={developerProfileCards}
          jobOpenings={jobOpenings}
          coverImg={
            <PrismicNextImage
              data-elastic-name="primary_image"
              field={image}
              width={1310}
              height={810}
              loading="eager"
            />
          }
          authorBio={author_name ? [authorBio] : []}
          coverVideoId={video_id}
          hire_developers_heading={hire_developers_heading}
          latest_posts_heading={latest_posts_heading}
          jobs_heading={jobs_heading}
          type={content_type || 'demand'}
          hubspotForms={getHubspotForms()}
        />
      </>
    );
  }
  const { intro, subscription_form, promo_box, share_icons, table_of_contents_title } = primary;
  const { data: promoBoxData } = promo_box as any;
  const { data: subscriptionBoxData } = subscription_form as any;
  const { data: shareIconsData } = share_icons as any;
  const { categories: categoriesData, authors } = context as any;

  const initializeSubscriptionCtaForm = formId => {
    if (formId && (window as any).hbspt) {
      (window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '8835306',
        formId: formId,
        target: `#hubspotForm_${formId}`,
        formInstanceId: `#hubspotForm_${formId}`,
        onFormReady: function (form) {
          const emailField = form.querySelector('input[type="email"]') as HTMLInputElement;
          if (emailField) {
            emailField.addEventListener('blur', () => {
              LogFormFills(asPath, ctx.visitor?.visitorId, {
                email: emailField.value,
                target: `#hubspotForm_${formId}`,
                formInstanceId: `#hubspotForm_${formId}`,
                portalId: '8835306',
                formId: formId,
                slice: 'BlogContent',
              });
            });
          }
        },
      });
      (window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '8835306',
        formId: formId,
        target: `#hubspotForm_${formId}_white`,
        formInstanceId: `hubspotForm_${formId}_white`,
        onFormReady: function (form) {
          const emailField = form.querySelector('input[type="email"]') as HTMLInputElement;
          if (emailField) {
            emailField.addEventListener('blur', () => {
              LogFormFills(asPath, ctx.visitor?.visitorId, {
                email: emailField.value,
                target: `#hubspotForm_${formId}_white`,
                formInstanceId: `hubspotForm_${formId}_white`,
                formId: formId,
                portalId: '8835306',
                slice: 'BlogContent',
              });
            });
          }
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      const eles = document.querySelectorAll('p:has(img) + p');
      eles.forEach(ele => {
        if (ele && ele.textContent && ele.textContent.length <= 80) {
          (ele as any).style.fontSize = '12px';
          (ele as any).style.lineHeight = '150%';
        }
      });
    });
    initializeSubscriptionCtaForm(subscriptionBoxData?.hubspot_form_id);
    () => window.removeEventListener('scroll', () => {});
  }, []);

  return (
    <section
      className={classNames(styles.root, 'wide-container')}
      data-slice-type={slice_type}
      data-slice-variation={variation}
    >
      <div className={styles.content}>
        <div className={styles.gridContainer}>
          {/* Left Side */}
          <div className={styles.leftContent}>
            {/* Promo Box */}
            <PromoBox promoBoxData={promoBoxData} />
            {/* Subscription Box */}
            <SubscriptionBox
              subscriptionBoxData={subscriptionBoxData}
              variant={'default'}
              formId={subscriptionBoxData?.hubspot_form_id}
            />
            <div className={styles.stickyOnScrollDesktop}>
              {/* Table Of Contents */}
              {items && items.length > 0 && (
                <TableOfContents
                  items={items}
                  table_of_contents_title={table_of_contents_title}
                  activeLink={activeLink}
                  renderInContent={false}
                />
              )}
              {/* Share Icons */}
              <ShareIcons shareIconsData={shareIconsData} />
              {/* Categories */}
              <Categories categoriesData={categoriesData} />
            </div>
          </div>

          {/* Right Side */}
          <div className={styles.rightContent}>
            {/* Share icons on mobile */}
            <div id="scroll-detect">
              <ShareButton shareIconsData={shareIconsData} />
              {asText(intro) && <PrismicRichText field={intro} className={styles.introText} />}

              {/* Table Of Contents */}
              {width < DesktopBreakpoint && (
                <TableOfContents
                  items={items}
                  table_of_contents_title={table_of_contents_title}
                  activeLink={activeLink}
                  renderInContent
                />
              )}

              {/* Items */}
              {items.map((item, index) => {
                const { data: blockQuoteData } = item.block_quote as any;
                return (
                  <Visible isVisible={inView => inView && setActiveLink(item)} key={item.title}>
                    <div className={styles.paragraphWrapper} id={convertToSnakeCase(item.title)}>
                      {React.createElement(
                        item.heading_type,
                        {
                          className: classNames(styles.heading, styles[item.heading_type], {
                            [styles.noTopMargin]: !asText(intro) && index === 0,
                          }),
                        },
                        item.title,
                      )}
                      {blockQuoteData?.content && item.block_quote_position === 'start' && (
                        <BlockQuote blockQuoteData={blockQuoteData} />
                      )}
                      <PrismicRichText
                        field={item.content}
                        showImageAsPopup
                        imagePopupVariant="dark_overlay"
                        className={classNames(styles.paragraphContent)}
                      />
                      {blockQuoteData?.content && item.block_quote_position === 'end' && (
                        <BlockQuote blockQuoteData={blockQuoteData} />
                      )}
                    </div>
                  </Visible>
                );
              })}
              {authors.map(({ author }) => {
                return author.data.name !== 'Turing Staff' && <Author authorData={author.data} />;
              })}
            </div>
            {/* Author */}

            <SubscriptionBox
              subscriptionBoxData={subscriptionBoxData}
              variant="background"
              formId={subscriptionBoxData?.hubspot_form_id}
            />

            {width < DesktopBreakpoint && (
              <>
                {/* Share Icons */}
                <ShareIcons shareIconsData={shareIconsData} />
                {/* Categories */}
                <Categories categoriesData={categoriesData} />
              </>
            )}
          </div>
        </div>
      </div>
      <Script
        strategy="lazyOnload"
        onLoad={() => initializeSubscriptionCtaForm(subscriptionBoxData?.hubspot_form_id)}
        charSet="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"
      />
    </section>
  );
};

const Author = ({ authorData }) => (
  <div className={styles.authorWrapper}>
    <div className={styles.firstRow}>
      {/* Note: below line has hardcoded value and right now best way to hide in case author image is avatar */}
      {asImageSrc(authorData.image) && !authorData.image.url.includes('ZiaJ5_Pdc1huKrn3_1-13.png') && (
        <div className={styles.picWrapper}>
          <PrismicNextImage field={authorData.image} />
        </div>
      )}
      <div className={styles.nameWrapper}>
        <div className={styles.eyebrowText}>{authorData.eyebrow_text}</div>
        <h3 className={styles.name}>{authorData.name}</h3>
      </div>
      <div className={styles.socialIconsWrapper}>
        {authorData.linkedin_profile && (
          <PrismicNextLink field={authorData.linkedin_profile}>
            <SVGLibrary name="LinkedIn" />
          </PrismicNextLink>
        )}
        {authorData.twitter_profile && (
          <PrismicNextLink field={authorData.twitter_profile}>
            <SVGLibrary name="Twitter" />
          </PrismicNextLink>
        )}
        {authorData.facebook_profile && (
          <PrismicNextLink field={authorData.facebook_profile}>
            <SVGLibrary name="Facebook" />
          </PrismicNextLink>
        )}
      </div>
    </div>
    <div className={styles.paragraph}>{authorData.description}</div>
  </div>
);
const PromoBox = ({ promoBoxData }) =>
  promoBoxData && (
    <div className={styles.promoBox}>
      <PrismicNextImage field={promoBoxData.image} />
      <PrismicLink className={styles.promoBoxTitle} field={promoBoxData.url}>
        {promoBoxData.title}{' '}
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H6V1.33333H1.33333V10.6667H10.6667V6H12V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM4.46667 8.46667L3.53333 7.53333L9.73333 1.33333H7.33333V0H12V4.66667H10.6667V2.26667L4.46667 8.46667Z"
            fill="white"
          />
        </svg>
      </PrismicLink>
    </div>
  );
const SubscriptionBox = ({ subscriptionBoxData, variant, formId }) => {
  const [width] = useWindowsSize();
  useEffect(() => {
    // Function to handle the click event
    const handleClick = event => {
      const { target } = event;

      // Check if the clicked element or any of its parents has the 'foo-class' class
      if (target.closest('.actions') && width < ResponsiveBreakpoint) {
        // Logic to submit a form
        const form = document.querySelector(`#hsForm_${formId}_hubspotForm_${formId}_white`);
        if (form) {
          form.dispatchEvent(
            new MouseEvent('submit', {
              bubbles: true,
              cancelable: true,
            }),
          );
        }
      }
    };

    // Add the event listener to the window
    window.addEventListener('click', handleClick);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    subscriptionBoxData && (
      <>
        <div
          className={classNames(styles.subscriptionBox, {
            [styles.backgroundWhite]: variant === 'background',
          })}
        >
          <h4 className={styles.subScriptionBoxTitle}>{subscriptionBoxData.title}</h4>
          <div className={styles.inputContainer}>
            {subscriptionBoxData?.hubspot_form_id && variant !== 'background' && (
              <div id={`hubspotForm_${subscriptionBoxData?.hubspot_form_id}`} />
            )}
            {subscriptionBoxData?.hubspot_form_id && variant === 'background' && (
              <div
                id={`hubspotForm_${subscriptionBoxData?.hubspot_form_id}_white`}
                className={classNames(styles.displayNone, {
                  [styles.displayWhite]: variant === 'background',
                })}
              />
            )}
            <style dangerouslySetInnerHTML={{ __html: HubspotCSS }}></style>
          </div>
          <PrismicRichText
            className={styles.subScriptionBoxDescription}
            field={subscriptionBoxData.description}
          ></PrismicRichText>
        </div>
      </>
    )
  );
};
const socialNetworkToDetailsMapping = {
  Facebook: {
    ShareComponent: FacebookShareButton,
  },
  LinkedIn: {
    ShareComponent: LinkedinShareButton,
  },
  Twitter: {
    ShareComponent: TwitterShareButton,
  },
};
const ShareIcons = ({ shareIconsData }) => {
  const { asPath } = useRouter();
  const path = asPath;
  const url = process.env.NEXT_PUBLIC_BASE_URL + path;
  const [isCopied, setIsCopied] = useState(false);
  return (
    shareIconsData && (
      <div className={styles.shareIcons}>
        <h2 className={styles.shareIconsTitle}>{shareIconsData.title || 'Share'}</h2>
        <div className={styles.iconsWrapper}>
          {shareIconsData?.icons.map(item => {
            if (item.icon === 'Link') {
              return (
                <PrismicNextLink
                  href={url}
                  key={item.icon}
                  className={classNames(styles.svgWrapper, styles.link)}
                  {...(item.icon === 'Link'
                    ? {
                        onClick: e => {
                          e.preventDefault();
                          navigator.clipboard.writeText(url);
                          setIsCopied(true);
                          setTimeout(() => {
                            setIsCopied(false);
                          }, MAX_FADE_OUT_TIME);
                        },
                      }
                    : {})}
                >
                  {isCopied ? (
                    <SVGLibrary name={'CircleCheckMark'} className={styles.animateSvg} />
                  ) : (
                    <SVGLibrary name={item.icon} />
                  )}
                </PrismicNextLink>
              );
            }

            const { ShareComponent } = socialNetworkToDetailsMapping[item.icon];
            return (
              <div className={styles.svgWrapper} key={item.icon}>
                <ShareComponent url={url} style={{ marginTop: '4px' }}>
                  <SVGLibrary name={item.icon} />
                </ShareComponent>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};
const Categories = ({ categoriesData }) => (
  <div className={styles.categories}>
    <div className={styles.categoriesWrapper}>
      {categoriesData.map((item: any) => {
        return item?.category?.data?.sub_heading ? (
          <div className={styles.category} key={item.category.data.sub_heading}>
            {item.category.data.sub_heading}
          </div>
        ) : null;
      })}
    </div>
  </div>
);
const TableOfContents = ({ items, table_of_contents_title, activeLink, renderInContent }) => (
  <div
    className={classNames(styles.tableOfContents, {
      [styles.renderInContent]: renderInContent,
    })}
  >
    <h3 className={styles.title}>{table_of_contents_title}</h3>
    {items.map(item => {
      const id = convertToSnakeCase(item.title);
      return (
        <Link href={`#${id}`} key={item.title}>
          {React.createElement(
            'div',
            {
              className: classNames(styles.heading, {
                [styles.active]: activeLink.title === item.title,
              }),
            },
            item.title,
          )}
        </Link>
      );
    })}
  </div>
);
const BlockQuote = ({ blockQuoteData }) => (
  <div className={styles.blockQuoteWrapper}>
    <PrismicRichText field={blockQuoteData.content} className={styles.blockQuoteContent} />
    <div className={styles.blockQuoteTitle}>{blockQuoteData.title}</div>
    <div className={styles.blockQuoteSubTitle}>{blockQuoteData.sub_title}</div>
  </div>
);
const ShareButton = ({ shareIconsData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleShareIcons = () => {
    setIsOpen(!isOpen);
  };

  const closeShareIcons = () => {
    setIsOpen(false);
  };
  const { asPath } = useRouter();
  const path = asPath;
  const url = process.env.NEXT_PUBLIC_BASE_URL + path;
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Function to check the scroll position
  const handleScroll = () => {
    const targetDiv = document.getElementById('scroll-detect');
    if (targetDiv) {
      const scrollTop = window.scrollY;
      const divTop = targetDiv.offsetWidth;
      const divHeight = targetDiv.offsetHeight;
      // Check if the window's scroll is within the div's vertical space
      if (scrollTop > divTop && scrollTop < divTop + divHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <div
        className={classNames(styles.stickyShareIconsContainer, {
          [styles.activeShareIcons]: isOpen,
        })}
      >
        <div className={classNames(styles.shareButton)} onClick={toggleShareIcons}>
          <SVGLibrary name="Share" />
        </div>
        {/* Other icons */}
        <div className={styles.expandIcons} onClick={closeShareIcons}>
          {shareIconsData?.icons.map(item => {
            if (item.icon === 'Link') {
              return (
                <PrismicNextLink
                  href={url}
                  key={item.icon}
                  className={classNames(styles.svgWrapper, styles.link)}
                  onClick={e => {
                    e.preventDefault();
                    navigator.clipboard.writeText(url);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, MAX_FADE_OUT_TIME);
                  }}
                >
                  {isCopied ? (
                    <SVGLibrary name="CircleCheckMark" className={styles.animateSvg} />
                  ) : (
                    <SVGLibrary name={item.icon} />
                  )}
                </PrismicNextLink>
              );
            }
            const { ShareComponent } = socialNetworkToDetailsMapping[item.icon];
            return (
              <ShareComponent url={url} className={styles.svgWrapper} key={item.icon}>
                <SVGLibrary name={item.icon} />
              </ShareComponent>
            );
          })}
        </div>
      </div>
    )
  );
};

export const convertToSnakeCase = str => {
  if (str && str !== null) {
    return str.replaceAll(/[\s\n]|[A-Z]/g, match =>
      match === ' ' || match === '\n' ? '_' : `_${match.toLowerCase()}`,
    );
  }
};
export default BlogContent;
