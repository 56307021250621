import { forwardRef, useState } from 'react';
import { Scrollbars as ReactCustomScrollbars } from 'react-custom-scrollbars-2';
import styles from './scrollbars.module.scss';

const Scrollbars = ({ children, scrollY, onScroll, ...otherProps }, ref) => {
  const [autoHide, setAutoHide] = useState(true);
  return (
    <ReactCustomScrollbars
      ref={ref}
      autoHide={autoHide}
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax="100vh"
      thumbMinSize={20}
      universal
      className={styles.root}
      onMouseEnter={() => setAutoHide(false)}
      onMouseLeave={() => setAutoHide(true)}
      renderTrackVertical={props => (
        <div {...props} className={styles.trackVertical} />
      )}
      renderThumbVertical={props => (
        <div {...props} className={styles.thumbVertical} />
      )}
      onScroll={onScroll}
      {...otherProps}
    >
      {children}
    </ReactCustomScrollbars>
  );
};

export default forwardRef(Scrollbars);
